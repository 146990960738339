import React, { useEffect, useState } from "react";
import { getCurrentData } from "./NewCarsIndex";

export function TimeDiff(date1, date2) {
  var diff = date2.getTime() - date1.getTime();

  var days = Math.floor(diff / (1000 * 60 * 60 * 24));
  diff -= days * (1000 * 60 * 60 * 24);

  var hours = Math.floor(diff / (1000 * 60 * 60));
  diff -= hours * (1000 * 60 * 60);

  var mins = Math.floor(diff / (1000 * 60));
  diff -= mins * (1000 * 60);

  var seconds = Math.floor(diff / 1000);
  diff -= seconds * 1000;

  const result =
    hours + " hours, " + mins + " minutes, " + seconds + " seconds";
  return result;
}

export default function Current() {
  const [current, setCurrent] = useState(null);

  async function getData() {
    setCurrent(await getCurrentData());
  }

  useEffect(() => {
    const int = setInterval(getData, 1000);
    getData();
    return () => {
      clearInterval(int);
    };
  }, []);

  if (current === null) {
    return <div>Kein Ladevorgang aktiv.</div>;
  }

  const start = new Date(current.startDate);
  const now = new Date();
  const timeSpan = TimeDiff(start, now);

  const timeDiff = now - start;

  const energy = current.energy - current.oldEnergy;
  const avgPower = Math.round((energy / timeDiff) * 60 * 60 * 1000);

  const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const localTime = start.toLocaleTimeString("de", dateOptions);

  return (
    <div>
      <h4>Aktueller Ladevorgang</h4>
      <dl>
        <dt>Name</dt>
        <dd>{current.name}</dd>
        <dt>Gestartet</dt>
        <dd>
          {localTime} ({timeSpan})
        </dd>
        <dt>Zähler Start</dt>
        <dd>{Math.round(current.oldEnergy) / 1000} kWh</dd>
        <dt>Zähler Ende</dt>
        <dd>{Math.round(current.energy) / 1000} kWh</dd>
        <dt>Geladene Energie</dt>
        <dd>{Math.round(current.energy - current.oldEnergy) / 1000} kWh</dd>
        <dt>Power Avg</dt>
        <dd>{avgPower} kW</dd>
        <dt>Solaranteil</dt>
        <dd>{current.percResult} %</dd>
        <dt>Aktueller Solaranteil</dt>
        <dd>{Math.round(current.currentPerc * 100)} %</dd>
      </dl>
    </div>
  );
}
