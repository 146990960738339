import React, { useState, useEffect } from "react";
import NewCar from "./NewCar";
import Current from "./Current";
import { Button } from "@mui/material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const domain = "https://ph.mauricefaber.de";
// const domain = "http://localhost:3001";

export async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 5000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

export async function getCurrentData() {
  var data = null;
  const result = await fetch(domain + "/current");
  if (result.ok) {
    data = await result.json();
  } else {
    data = null;
  }
  return data;
}

export default function NewCarsIndex() {
  const [users, setUsers] = useState([]);

  const [selectedUser, setSelectedUser] = useState(undefined);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function getChargings(name) {
    var result = await fetch(domain + "/chargings?name=" + name);
    if (result.ok) {
      var data = await result.json();
      return data;
    }
    return [];
  }

  async function getUserData(data) {
    var userData = [];
    for await (const user of data) {
      const userName = user.userName;
      var userChargings = await getChargings(userName);

      var firstDate = null;
      var lastDate = null;

      for (var i = 0; i < userChargings.length; i++) {
        const ticks = parseFloat(userChargings[i].startDate);
        const ticks2 = parseFloat(userChargings[i].endDate);

        const startDateDate = new Date(ticks);
        const endDateDate = new Date(ticks2);

        if (!firstDate || startDateDate < firstDate) {
          firstDate = startDateDate;
        }
        if (!lastDate || endDateDate > lastDate) {
          lastDate = endDateDate;
        }

        const startDate = startDateDate.toISOString();
        const endDate = endDateDate.toISOString();

        userChargings[i].startDate = startDate;
        userChargings[i].startDateDate = startDateDate;
        userChargings[i].endDate = endDate;
        userChargings[i].endDateDate = endDateDate;
      }

      userData.push({
        name: userName,
        chargings: userChargings,
        firstDate: firstDate,
        lastDate: lastDate,
      });
    }
    setUsers(userData);
  }

  async function getData() {
    try {
      setLoading(true);
      const response = await fetchWithTimeout(domain + "/users", {
        timeout: 5000,
      });
      const users = await response.json();
      await getUserData(users);
      setMessage("");
    } catch (error) {
      // Timeouts if the request takes
      // longer than 6 seconds
      setMessage(
        "Keine Verbindung zum Server. Vielleicht keine VPN-Verbindung."
      );
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("selectedUser");
    if (user) {
      setSelectedUser(users.find((u) => u.name === user));
    }
  }, [users]);

  function setUser(e) {
    setSelectedUser(users.find((u) => u.name === e.target.value));
    localStorage.setItem("selectedUser", e.target.value);
  }

  return (
    <div>
      <h2>
        E-Auto Ladungen{" "}
        <small>
          <Button
            variant="outlined"
            onClick={(e) => {
              setUsers([]);
              getData();
            }}
          >
            🔄
          </Button>
        </small>
      </h2>
      {/* {users.map((user) => (
        <NewCar user={user} />
      ))} */}

      <Current />
      <br />

      {users && users.length > 0 ? (
        <Select
          variant="outlined"
          size="small"
          onChange={(e) => setUser(e)}
          value={selectedUser?.name}
        >
          <MenuItem value={undefined}>Keiner</MenuItem>
          {users.map((user) => (
            <MenuItem key={user.name} value={user.name}>
              {user.name}
            </MenuItem>
          ))}
        </Select>
      ) : null}

      {loading ? (
        <span className="loader">
          <i>Daten abrufen...</i>
        </span>
      ) : (
        <>
          {message ? (
            <span className="errorMessage">{message}</span>
          ) : (
            <>
              {selectedUser ? (
                <NewCar user={selectedUser} />
              ) : (
                <span>Kein Benutzer ausgewählt.</span>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
