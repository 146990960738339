import { Bar } from "react-chartjs-2";
import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";

import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button, Box } from "@mui/material";

export default function NewCar({ user }) {
  const [filterFirstDate, setFirstDate] = useState(undefined);
  const [filterLastDate, setLastDate] = useState(undefined);
  const [dateRange, setDateRange] = useState("");

  useEffect(() => {
    setDateRange(
      filterFirstDate?.toISOString().split("T")[0] +
        " - " +
        filterLastDate?.toISOString().split("T")[0]
    );
  }, [filterFirstDate, filterLastDate]);

  useEffect(() => {
    // setFirstDate(user.firstDate);
    // setFirstDate(new Date());
    var y = user.lastDate.getFullYear();
    var m = user.lastDate.getMonth();
    var d = user.lastDate.getDate();
    var firstDay = new Date(y, m, 1, 0, 0, 0);
    // var lastDay = new Date(y, m + 1, 0);

    var lastDay = new Date(y, m, d, 23, 59, 59);

    setFirstDate(firstDay);
    setLastDate(lastDay);
  }, []);

  function toDay() {
    const now = new Date();

    var y = now.getFullYear();
    var m = now.getMonth();
    var d = now.getDate();
    var firstDay = new Date(
      new Date(y, m, d, 0, 0, 0).getTime() + now.getTimezoneOffset() * -60000
    );

    var lastDay = new Date(
      new Date(y, m, d, 23, 59, 59).getTime() + now.getTimezoneOffset() * -60000
    );

    console.log("today: ", firstDay, lastDay);
    setFirstDate(firstDay);
    setLastDate(lastDay);
  }

  function thisYear() {
    const now = new Date();

    var y = now.getFullYear();
    var firstDay = new Date(
      new Date(y, 1, 1, 0, 0, 0).getTime() + now.getTimezoneOffset() * -60000
    );

    var lastDay = new Date(new Date(y, 11, 31, 23, 59, 59).getTime());

    console.log("this year: ", firstDay, lastDay);
    setFirstDate(firstDay);
    setLastDate(lastDay);
  }

  function thisMonth(offset = 0) {
    const now = new Date();

    var y = now.getFullYear();
    var m = now.getMonth() + offset;
    var d = offset == 0 ? now.getDate() : 31;
    var firstDay = new Date(
      new Date(y, m, 1, 0, 0, 0).getTime() + now.getTimezoneOffset() * -60000
    );

    var lastDay = new Date(
      new Date(y, m, d, 23, 59, 59).getTime() + now.getTimezoneOffset() * -60000
    );

    console.log("this year: ", firstDay, lastDay);
    setFirstDate(firstDay);
    setLastDate(lastDay);
  }

  const groups = user.chargings.reduce((groups, charging) => {
    const dateParts = charging.startDate.split("T")[0].split("-");
    const date = dateParts[0] + "-" + dateParts[1];

    const isInDateRange =
      charging.startDateDate >= filterFirstDate &&
      charging.endDateDate <= filterLastDate;

    if (isInDateRange) {
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(charging);
    }

    return groups;
  }, {});

  const options = {
    maintainAspectRation: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      animation: false,
    },
    responsive: true,
    scales: {
      y: {
        stacked: true,
      },
    },
  };

  const chargesOfMonths = [];

  Object.keys(groups).forEach((key, index) => {
    chargesOfMonths.push({
      name: key,
      chargings: groups[key],
    });
  });

  const carLabels = chargesOfMonths.map((arr) => arr.name);

  const consumptionAllDataCar = chargesOfMonths.map((arr, index) => {
    let energyCharged = 0;
    arr.chargings.forEach((charging) => {
      let charged = (charging.endEnergy - charging.startEnergy) / 1000.0;
      energyCharged += charged;
    });
    return energyCharged;
  });

  const consumptionDataCar = chargesOfMonths.map((arr, index) => {
    let energyCharged = 0;
    arr.chargings.forEach((charging) => {
      let charged = (charging.endEnergy - charging.startEnergy) / 1000.0;
      const percFactor = charging.solarPerc ? charging.solarPerc : 0.0;
      const solarCharged = percFactor * charged;
      energyCharged += charged - solarCharged;
    });
    return energyCharged;
  });

  const consumptionSolarDataCar = chargesOfMonths.map((arr, index) => {
    let fromSolar = 0;
    arr.chargings.forEach((charging) => {
      let charged = (charging.endEnergy - charging.startEnergy) / 1000.0;
      const percFactor = charging.solarPerc ? charging.solarPerc : 0.0;
      const solarCharged = percFactor * charged;
      fromSolar += solarCharged;
    });
    return fromSolar;
  });

  const costsDataCar = chargesOfMonths.map((arr, index) => {
    let costs = 0;
    arr.chargings.forEach((charging) => {
      let charged = (charging.endEnergy - charging.startEnergy) / 1000.0;
      const percFactor = charging.solarPerc ? charging.solarPerc : 0.0;
      const effectiveCurrentPrice =
        Math.round((1.0 - percFactor) * charging.electricityPrice * 100) / 100;

      const effectivePrice =
        Math.round(effectiveCurrentPrice * charged * 100) / 100;

      costs += effectivePrice;
    });
    return costs;
  });

  const kWhPricesData = chargesOfMonths.map((arr, index) => {
    let costs = 0;
    let kWhCharged = 0;
    arr.chargings.forEach((charging) => {
      let charged = (charging.endEnergy - charging.startEnergy) / 1000.0;
      const percFactor = charging.solarPerc ? charging.solarPerc : 0.0;
      const effectiveCurrentPrice =
        Math.round((1.0 - percFactor) * charging.electricityPrice * 100) / 100;

      const effectivePrice =
        Math.round(effectiveCurrentPrice * charged * 100) / 100;

      kWhCharged += charged;
      costs += effectivePrice;
    });
    return costs / kWhCharged;
  });

  const charDataCar = {
    labels: carLabels,
    options: {
      scales: {
        yAxes: [
          {
            name: "A",
            type: "linear",
            position: "left",
          },
          {
            name: "B",
            type: "linear",
            position: "right",
          },
        ],
      },
    },
    datasets: [
      {
        label: "Gesamt kWh",
        data: consumptionAllDataCar,
        backgroundColor: "#003f5c",
        stack: "stack0",
        yAxisID: "A",
      },
      {
        label: "Grid kWh",
        data: consumptionDataCar,
        backgroundColor: "#ff6361",
        stack: "stack1",
        yAxisID: "A",
      },
      {
        label: "Solar kWh",
        data: consumptionSolarDataCar,
        backgroundColor: "#ffa600",
        stack: "stack1",
        yAxisID: "A",
      },
      {
        label: "Kosten €",
        data: costsDataCar,
        backgroundColor: "rgba(255, 30, 0, 1)",
        stack: "stack2",
        yAxisID: "A",
      },
      {
        label: "€/kWh",
        data: kWhPricesData,
        backgroundColor: "rgba(30, 100, 100, 1)",
        // stack: "stack2",
        yAxisID: "B",
      },
    ],
  };

  //   const dateOptions = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   };
  const dayGroups = user.chargings.reduce((groups, charging) => {
    // const date = new Date(charging.startDate).toLocaleDateString(
    //   "de",
    //   dateOptions
    // );

    const date = "KEY"; //alles in eine liste

    const isInDateRange =
      charging.startDateDate >= filterFirstDate &&
      charging.endDateDate <= filterLastDate;

    if (isInDateRange) {
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(charging);
    }

    return groups;
  }, {});

  var dayData = [];
  Object.keys(dayGroups).map((key, index) => {
    const items = dayGroups[key];

    var charged = items.reduce(
      (sum, a) => sum + (a.endEnergy - a.startEnergy) / 1000.0,
      0
    );

    charged = Math.round(charged * 10) / 10;

    var fromSolar = items.reduce(
      (sum, a) =>
        sum +
        ((a.endEnergy - a.startEnergy) * (a.solarPerc ? a.solarPerc : 0.0)) /
          1000.0,
      0
    );
    fromSolar = Math.round(fromSolar * 10) / 10;

    var costs = items.reduce(
      (sum, a) =>
        sum +
        ((a.endEnergy - a.startEnergy) *
          (a.solarPerc ? 1.0 - a.solarPerc : 1.0) *
          a.electricityPrice) /
          1000.0,
      0
    );
    costs = Math.round(costs * 1000) / 1000;

    const costsPerKwh = Math.round((costs / charged) * 1000) / 10;

    var solarPerc = (fromSolar / charged) * 100.0;
    solarPerc = Math.round(solarPerc);

    const day = {
      caption: key,
      charged: charged,
      costs: costs,
      costsPerKwh: costsPerKwh,
      solarEnergy: fromSolar,
      solarPerc: solarPerc,
      chargings: items,
    };

    if (charged > 0.0) {
      dayData.push(day);
    }
  });

  return (
    <div>
      <h3>
        {user.name}
        <small> {dateRange}</small>
      </h3>

      <div>
        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Von"
            value={filterFirstDate}
            type="date"
            onChange={(e) => setFirstDate(new Date(e.target.value))}
            // defaultValue={filterFirstDate?.toISOString().split("T")[0]}
          ></DatePicker>
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Bis"
            value={filterLastDate}
            type="date"
            onChange={(e) => setFirstDate(new Date(e.target.value))}
            // defaultValue={filterFirstDate?.toISOString().split("T")[0]}
          ></DatePicker>
        </LocalizationProvider> */}

        <input
          type="date"
          onChange={(e) => setFirstDate(new Date(e.target.value))}
          defaultValue={filterFirstDate?.toISOString().split("T")[0]}
        />
        <input
          type="date"
          onChange={(e) => setLastDate(new Date(e.target.value))}
          defaultValue={filterLastDate?.toISOString().split("T")[0]}
        />

        <Box />

        <Button variant="outlined" size="small" onClick={(e) => toDay()}>
          Heute
        </Button>
        <Button
          variant="outlined"
          size="small"
          type="button"
          onClick={(e) => thisMonth()}
        >
          Dieser Monat
        </Button>

        <Button variant="outlined" size="small" onClick={(e) => thisMonth(-1)}>
          Letzter Monat
        </Button>
        <Button variant="outlined" size="small" onClick={(e) => thisYear()}>
          Dieses Jahr
        </Button>
      </div>

      <h2>Diagramm</h2>
      <Bar className="bar" options={options} data={charDataCar} />

      <h2>Tabelle</h2>

      {dayData.map((day, index) => {
        return (
          <div>
            <h4 className="center">
              &nbsp;
              <small>
                {day.charged}
                kWh {day.solarEnergy}
                kWh PV ({day.solarPerc}%) {"-> "}
                {day.costsPerKwh} ct/kWh {" ("}
                {day.costs.toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                })}
                {")"}
              </small>
            </h4>
            <table className="detailedChart monospace">
              <thead>
                <tr>
                  <th>Zeit</th>
                  <th>Energie</th>
                  <th>Strompreis</th>
                  <th>Solar</th>
                  <th>Kosten</th>
                </tr>
              </thead>
              <tbody>
                {day.chargings.map((charging, itemIndex) => {
                  let energyCharged =
                    (charging.endEnergy - charging.startEnergy) / 1000.0;
                  energyCharged = Math.round(energyCharged * 100) / 100;
                  const startDate = new Date(charging.startDate);
                  const endDate = new Date(charging.endDate);

                  const localTime = startDate.toLocaleTimeString("de");
                  const localEndTime = endDate.toLocaleTimeString("de");

                  const time =
                    startDate.toLocaleDateString("de") +
                    " " +
                    localTime +
                    "-" +
                    localEndTime;

                  const percFactor = charging.solarPerc
                    ? charging.solarPerc
                    : 0.0;
                  const percFromSolar = Math.round(percFactor * 100);

                  const effectiveCurrentPrice =
                    Math.round(
                      (1.0 - percFactor) * charging.electricityPrice * 100
                    ) / 100;

                  const effectivePrice =
                    Math.round(effectiveCurrentPrice * energyCharged * 100) /
                    100;

                  if (energyCharged <= 0.01) {
                    return null;
                  }

                  return (
                    <tr key={charging.startDate + charging.endDate}>
                      <td>{time}</td>
                      {/* <td>{localTime}</td>
                      <td>{localEndTime}</td> */}
                      <td>{energyCharged} kWh</td>
                      <td>{charging.electricityPrice} €</td>
                      <td>{percFromSolar} %</td>
                      <td>{effectivePrice} €</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}

      {/* <table className="detailedChart monospace">
        <thead>
          <tr>
            <th>Startdatum</th>
            <th>Energie</th>
            <th>Strompreis</th>
            <th>Solar</th>
            <th>Kosten</th>
          </tr>
        </thead>
        <tbody>
          {user.chargings.map((charging, index) => {
            let energyCharged =
              (charging.endEnergy - charging.startEnergy) / 1000.0;
            energyCharged = Math.round(energyCharged * 100) / 100;
            const startDate = new Date(charging.startDate);

            // const dateOptions = {
            //   year: "numeric",
            //   month: "2-digit",
            //   day: "2-digit",
            // };
            // const localTime = startDate.toLocaleTimeString("de", dateOptions);
            const localTime = startDate.toLocaleTimeString("de");

            const percFactor = charging.solarPerc ? charging.solarPerc : 0.0;
            const percFromSolar = Math.round(percFactor * 100);

            const effectiveCurrentPrice =
              Math.round((1.0 - percFactor) * charging.electricityPrice * 100) /
              100;

            const effectivePrice =
              Math.round(effectiveCurrentPrice * energyCharged * 100) / 100;

            if (energyCharged < 0.05) {
              return null;
            }
            // console.log(charging.electricityPrice, startDate, index, charging);

            return (
              <tr key={charging.startDate + charging.endDate}>
                <td>{localTime}</td>
                <td>{energyCharged} kWh</td>
                <td>{charging.electricityPrice} €</td>
                <td>{percFromSolar} %</td>
                <td>{effectivePrice} €</td>
              </tr>
            );
          })}
        </tbody>
      </table> */}
    </div>
  );
}
